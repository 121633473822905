<template>
  <div v-if="typeof value !== 'string'">
    <el-checkbox :label="$t('interface_editor.component_editor.map_tools.map_center_use_field')" v-model="coordinates_from_component"></el-checkbox>
    <el-select
      v-if="coordinates_from_component"
      v-model="select_value"
    >
      <el-option
        v-for="(item, index) in components.filter((cmp) => cmp.properties.name && cmp.initialType.indexOf('geometry_field') > -1)"
        :key="index"
        :label="`${item.name} (${item.properties.name})`"
        :value="item.properties.name"
      ></el-option>
    </el-select>
    <el-input
      v-if="!coordinates_from_component"
      :placeholder="$t('interface_editor.component_editor.map_tools.map_center_comma')"
      v-model="field_value"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'index',
  inject: ['getComponents'],
  props: {
    value: {
      type: Object | String,
      default: () => {
        return {
          coordinates_from_component: false,
          value: null
        }
      }
    }
  },
  data: function () {
    return {
      select_value: null,
      field_value: null,
      coordinates_from_component: false,
      components: this.getComponents()
    }
  },
  watch: {
    value: {
      handler: function (newValue) {
        this.coordinates_from_component = newValue.coordinates_from_component
        const fieldName = this.getFieldName()
        if (this[fieldName] !== newValue.value) {
          this[fieldName] = newValue.value
        }
      }
    },
    field_value: {
      handler: function() {
        this.updateValue()
      }
    },
    select_value: {
      handler: function() {
        this.updateValue()
      }
    },
    coordinates_from_component: {
      handler: function() {
        this.updateValue()
      }
    }
  },
  mounted () {
    if (typeof this.value === 'string') {
      this.$emit('change', {
        coordinates_from_component: false,
        value: '' + this.value
      })
    }
    if (this.value.coordinates_from_component) {
      this.select_value = this.value.value
    } else {
      this.field_value = this.value.value
    }
    this.coordinates_from_component = this.value.coordinates_from_component
  },
  methods: {
    updateValue() {
      this.value.coordinates_from_component = this.coordinates_from_component
      this.value.value = this[this.getFieldName()]
      this.$emit('change', this.value)
    },
    getFieldName() {
      return (this.coordinates_from_component ? 'select' : 'field') + '_value'
    }
  }
}
</script>

<style scoped>

</style>
